.fade-enter:not(.home-section) {
	opacity: 0.01;
}

.fade-enter.fade-enter-active:not(.home-section) {
	opacity: 1;
	transition: opacity 300ms ease-in;
}

.fade-exit:not(.home-section) {
	opacity: 1;
}

.fade-exit.fade-exit-active:not(.home-section) {
	opacity: 0.01;
	transition: opacity 300ms ease-in;
}

div.transition-group {
	position: relative;
}
section.route-section {
	position: relative;
	width: 100%;
	top: 0;
	left: 0;
}

.Page {
	padding-top: 10px;
}
