#home-title {
	font-size: 8vw;
}

#home-content .row {
	background-color: var(--container-background);
	padding-bottom: 1px !important;
}
#home-content .row:not(:last-child) {
	margin-bottom: 25px;
}

#home-content .card {
	background-color: var(--container-background);
	margin-left: 5px;
	margin-right: 5px;
	border-radius: 8px;
	transition: box-shadow 0.3s ease;
	min-width: 160px;
}
#home-content .card-img-top {
	border-radius: 8px 8px 0 0;
}
#home-content .card-body {
	padding: 0.75rem;
}

#home-content .card:hover {
	box-shadow: 0 0 5px 1px #383933;
}

#home-content .card-footer {
	background-color: rgba(0, 0, 0, 0.075);
}

#home-row-nav button {
	font-weight: bold;
	margin-bottom: 5px;
}
#home-row-nav button:not(:last-child) {
	margin-right: 5px;
}
#home-row-nav a {
	color: var(--accent-button-text) !important;
	text-decoration: none;
}

/* Bootstrap small breakpoint */
@media (min-width: 576px) {
	#home-content .row {
		padding-bottom: 6px !important;
	}
	#home-content .card {
		margin-bottom: 10px;
	}
}
