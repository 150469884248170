.bannerImg,
.bannerImg svg {
	max-height: 500px;
}

.jumbotron {
	background-color: var(--block-background) !important;
}
.jumbotron:last-child {
	margin-bottom: 0px;
}

.section-nav {
	padding-left: 0;
	border-left: 1px solid var(--accent);
}
.section-nav ul {
	padding-left: 1.25rem;
}
.toc-entry {
	display: block;
}
.toc-entry a {
	display: block;
	padding: 0.125rem 1.5rem;
	color: var(--toc-text) !important;
	transition: color 0.3s ease;
}
.toc-entry a.active {
	color: var(--accent) !important;
}
.bd-toc {
	position: sticky !important;
	top: 2rem;
	height: calc(100vh - 4rem);
	overflow-y: auto;

	order: 2;
	padding-top: 1.5rem;
	padding-bottom: 1.5rem;
	font-size: 0.875rem;
}

.subrow {
	background-color: var(--subrow-background);
}
.subrow:not(:last-of-type) {
	margin-bottom: 10px;
}
