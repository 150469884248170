.toggle-switch {
	position: relative;
	display: inline-block;
	width: 60px;
	height: 34px;
	margin-bottom: 0;
}

.toggle-switch input {
	opacity: 0;
	width: 0;
	height: 0;
}

.toggle-slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: var(--accent);
	-webkit-transition: var(--transition-length);
	transition: var(--transition-length);
}

.toggle-slider:before {
	position: absolute;
	content: "";
	height: 26px;
	width: 26px;
	left: 4px;
	bottom: 4px;
	background-color: var(--page-background);
	-webkit-transition: var(--transition-length);
	transition: var(--transition-length);
}

input:checked + .toggle-slider:before {
	-webkit-transform: translateX(26px);
	-ms-transform: translateX(26px);
	transform: translateX(26px);
}

/* Rounded toggle-sliders */
.toggle-slider.round {
	border-radius: 34px;
}

.toggle-slider.round:before {
	border-radius: 50%;
}
